import HomePage from "./components/home/home.vue";
import HeaderPart from "./components/header/header.vue";
import FooterPart from "./components/footer/footer.vue";
import AboutPage from "./components/about-us/about-us.vue";
import ContactPage from "./components/contact/contact-us.vue";
import ClientPage from "./components/client-features/client-features.vue";
import CompanyPage from "./components/company-features/company-features.vue";
import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    name: "HomePage",
    component: HomePage,
    path: "/",
  },
  {
    name: "HeaderPart",
    component: HeaderPart,
    path: "/header",
  },
  {
    name: "FooterPart",
    component: FooterPart,
    path: "/footer",
  },
  {
    name: "AboutPage",
    component: AboutPage,
    path: "/about-us",
  },
  {
    name: "ContactPage",
    component: ContactPage,
    path: "/contact",
  },
  {
    name: "ClientPage",
    component: ClientPage,
    path: "/client-features",
  },
  {
    name: "CompanyPage",
    component: CompanyPage,
    path: "/company-features",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
