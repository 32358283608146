<template>
  <!--OFFCANVAS MENU PART STARTS-->

  <div class="off_canvars_overlay"></div>
  <div class="offcanvas_menu">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="offcanvas_menu_wrapper">
            <div class="canvas_close">
              <a href="javascript:void(0)"><i class="fa fa-times"></i></a>
            </div>
            <div class="offcanvas-brand text-center mb-40">
              <img src="assets/images/logo-2.png" alt="" />
            </div>
            <div id="menu" class="text-left">
              <ul class="offcanvas_main_menu">
                <li class="menu-item-has-children active">
                  <router-link
                    to="/"
                    class="header-link"
                    @click="deactivateMenuOverlay()"
                    >Home
                  </router-link>
                </li>
                <li class="menu-item-has-children active">
                  <router-link
                    to="/company-features"
                    class="header-link"
                    @click="deactivateMenuOverlay()"
                  >
                    Company
                  </router-link>
                </li>
                <li class="menu-item-has-children active">
                  <router-link
                    to="/client-features"
                    class="header-link"
                    @click="deactivateMenuOverlay()"
                    >Client</router-link
                  >
                </li>
                <li class="menu-item-has-children active">
                  <router-link
                    to="/about-us"
                    class="header-link"
                    @click="deactivateMenuOverlay()"
                    >About us</router-link
                  >
                </li>
                <li class="menu-item-has-children active">
                  <router-link
                    to="/contact"
                    class="header-link"
                    @click="deactivateMenuOverlay()"
                    >Contact</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="footer-widget-info">
              <ul>
                <li>
                  <a href="#"
                    ><i class="fal fa-envelope"></i> contact@mangosoft.tech</a
                  >
                </li>
                <li>
                  <a href="#"><i class="fal fa-phone"></i> +1 424 413 5692</a>
                  <a href="#"><i class="fal"></i> +380 (98) 067 14 98</a>
                </li>
                <li>
                  <a href="#" class="menu-overlay-location"
                    ><i class="fal fa-map-marker-alt"></i> 237 Kearny Street,
                    CA, 94108, USA</a
                  >
                  <a href="#"
                    ><i class="fal"></i> Kozatska street 122/4 office 207,
                    03022, Ukraine</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--====== OFFCANVAS MENU PART ENDS ======-->
  <div class="appie-header-area appie-header-4-area appie-sticky">
    <div class="container">
      <div class="header-nav-box header-nav-box-5">
        <div class="row align-items-center">
          <div class="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
            <div class="appie-logo-box">
              <a href="#">
                <img
                  src="assets/images/app-logo/logo-pink.png"
                  alt=""
                  class="logo-header"
                />
              </a>
            </div>
          </div>

          <div class="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
            <div class="appie-header-main-menu">
              <ul>
                <li>
                  <router-link to="/" class="header-link">Home </router-link>
                </li>
                <li>
                  <router-link to="/company-features" class="header-link"
                    >Company</router-link
                  >
                </li>
                <li>
                  <router-link to="/client-features" class="header-link"
                    >Client</router-link
                  >
                </li>
                <li>
                  <router-link to="/about-us" class="header-link"
                    >About us</router-link
                  >
                </li>
                <li>
                  <router-link to="/contact" class="header-link"
                    >Contact</router-link
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-7 col-sm-6 col-6 order-2 order-sm-3">
            <div class="appie-btn-box text-right">
              <a
                class="main-btn ml-30 get-started-btn"
                @click="scrollToDownload()"
                >Get Started</a
              >
              <div
                class="toggle-btn ml-30 canvas_open d-lg-none d-block overlay-menu-activate-btn"
              >
                <i class="fa fa-bars"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderPart",
  methods: {
    navigateToFooter() {
      let el = document.getElementById("footer");
      el?.scrollIntoView();
    },

    scrollToDownload() {
      const el = document.getElementById("get-started");
      console.log(el);
      el?.scrollIntoView();
    },

    deactivateMenuOverlay() {
      document
        .getElementsByClassName(".offcanvas_menu_wrapper")
        .removeClass("active");
      document
        .getElementsByClassName(".off_canvars_overlay")
        .removeClass("active");
    },
  },

  //  navigateToFooter() {
  //   let el = document.getElementById('footer');
  //   el?.scrollIntoView();
  // }

  // scrollToDownload() {
  //   const el = document.getElementById("get-started");
  //   console.log(el);
  //   el?.scrollIntoView();
  // }

  // deactivateMenuOverlay(){
  //   $('.offcanvas_menu_wrapper,.off_canvars_overlay').removeClass('active');
  // }
};
</script>
<style>
.header-link {
  color: #f84a6e !important;
}
.get-started-btn {
  color: #fff !important;
}
.logo-header {
  width: 100px;
  height: 100px;
}

/* background color when header is fixed at the top*/
.appie-header-area.appie-header-4-area.appie-sticky.sticky {
  background: #fff;
}
.overlay-menu-activate-btn {
  color: #f84a6e !important;
}
</style>
