<template>
  <HeaderPart />
  <!--====== APPIE HERO PART START ======-->

  <section class="appie-hero-area appie-hero-7-area" id="home-page-top">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div
            class="appie-hero-content appie-hero-content-6 appie-hero-content-7"
          >
            <h1 class="appie-title home-intro-title">
              Biggest Live Shopping Network
            </h1>
            <p class="home-intro-paragraph">
              Elevate your business. Create live sessions, manage your products
              and orders, engage with your clients in real-time, set shipping
              prices.
            </p>
            <ul>
              <li class="home-page-store-download-btn">
                <a href="#"
                  ><i class="fab fa-apple"></i>
                  <span>Available on the <span>App Store</span></span></a
                >
              </li>
              <li class="home-page-google-download-btn">
                <a class="item-2" href="#"
                  ><i class="fab fa-google-play"></i>
                  <span>Available on the <span>Google Play</span></span></a
                >
              </li>
            </ul>
            <div class="info"></div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="appie-hero-thumb-6">
            <div
              class="thumb wow animated fadeInUp text-center"
              data-wow-duration="1000ms"
              data-wow-delay="600ms"
            >
              <img src="assets/images/zelega2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-8-shape-2">
      <img src="assets/images/shape/4.png" alt="" />
    </div>
    <div class="home-8-shape-3">
      <img src="assets/images/shape/1.png" alt="" />
    </div>
    <div class="home-8-shape-4">
      <img src="assets/images/shape/2.png" alt="" />
    </div>
  </section>

  <!--====== APPIE HERO PART ENDS ======-->

  <!--====== APPIE FEATURES PART START ======-->

  <section class="appie-features-area pt-100 pb-100" id="features">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-3">
          <div class="appie-features-tabs-btn">
            <div
              class="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                class="nav-link active"
                id="v-pills-home-tab"
                data-toggle="pill"
                href="#v-pills-home"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
                ><i class="fas fa-user"></i> Client View</a
              >
              <a
                class="nav-link"
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                ><i class="fas fa-store-alt"></i>Company View</a
              >
              <a
                class="nav-link"
                id="v-pills-messages-tab"
                data-toggle="pill"
                href="#v-pills-messages"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
                ><i class="fas fa-tshirt"></i>Product View</a
              >
              <a
                class="nav-link"
                id="v-pills-settings-tab"
                data-toggle="pill"
                href="#v-pills-settings"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                <i class="fas fa-caret-right"></i>Live Session</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="tab-content" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center wow animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img src="assets/images/feed.png" alt="" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3 class="title">Client<br /></h3>
                    <p>
                      Check latest updates, look and search for different
                      companies, checkout live sessions, checkout latest
                      products, watch and get most popular live sessions,
                      recommended products and companies.
                    </p>
                    <a
                      class="main-btn home-page-features-btn"
                      routerLink="/client"
                      >Learn More</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img src="assets/images/dashboard.png" alt="" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3 class="title">Company <br /></h3>
                    <p>
                      Create your first live stream and add products. Check the
                      number of live streams and number of clients on each live
                      stream, number of followers, products change, chart of
                      sales from live and profile.
                    </p>
                    <a
                      class="main-btn home-page-features-btn"
                      routerLink="/company"
                      >Learn More</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-messages"
              role="tabpanel"
              aria-labelledby="v-pills-messages-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img src="assets/images/product-detail.png" alt="" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3 class="title">Product View <br /></h3>
                    <p>
                      Product screen provides a presentation with multiple
                      pictures for product, description of product even related
                      products from the same category.
                    </p>
                    <a
                      class="main-btn home-page-features-btn"
                      routerLink="/client"
                      >Learn More</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-settings"
              role="tabpanel"
              aria-labelledby="v-pills-settings-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img src="assets/images/zelega.png" alt="" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3 class="title">Live Screen View <br /></h3>
                    <p>
                      Checkout ongoing live session through live screen view.
                      Check the products that are available for sale during the
                      live session, directly purchase the product, without
                      leaving the screen. Add comments, follow or like the
                      company.
                    </p>
                    <a
                      class="main-btn home-page-features-btn"
                      routerLink="/company"
                      >Learn More</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="features-shape-1">
      <img src="assets/images/shape/shape-6.png" alt="" />
    </div>
    <div class="features-shape-2">
      <img src="assets/images/shape/shape-7.png" alt="" />
    </div>
    <div class="features-shape-3">
      <img src="assets/images/shape/shape-8.png" alt="" />
    </div>
  </section>

  <!--====== APPIE FEATURES PART ENDS ======-->

  <section class="appie-services-2-area pb-100 pt-100" id="service">
    <div class="container">
      <div class="row align-items-end">
        <div class="col-lg-6 col-md-8">
          <div class="appie-section-title">
            <h3 class="appie-title">How does it work</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service-2 mt-30 wow animated fadeInUp how-works-card"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <div class="homme-how-works-icon">
              <span>1</span>
              <h4>Download</h4>
            </div>
            <p>Download our app from Play Store or Apple Store.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service-2 item-2 mt-30 wow animated fadeInUp how-works-card"
            data-wow-duration="2000ms"
            data-wow-delay="400ms"
          >
            <div class="homme-how-works-icon">
              <span>2</span>
              <h4>Register</h4>
            </div>
            <p>Create your account using your email address or phone number.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service-2 item-3 mt-30 wow animated fadeInUp how-works-card"
            data-wow-duration="2000ms"
            data-wow-delay="600ms"
          >
            <div class="homme-how-works-icon">
              <span>3</span>
              <h4>Live Shopping</h4>
            </div>
            <p>Create your live stream and add products.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service-2 item-4 mt-30 wow animated fadeInUp how-works-card"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <div class="homme-how-works-icon">
              <span>4</span>
              <h4>Profits</h4>
            </div>
            <p>Enjoy your profits.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== how does it work PART ENDS ======-->

  <!--====== APPIE DOWNLOAD PART START ======-->

  <section
    class="appie-download-area pt-150 pb-160 mb-100 download-area-home"
    id="get-started"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="appie-download-content">
            <span>Download Our App</span>
            <h3 class="title">
              App is available <br />
              on app store and play store.
            </h3>
            <p>
              Get started today, download our app on Play Store for Android or
              on Apple Store for iOS.
            </p>
            <ul>
              <li>
                <a href="#" class="download-btn-ios">
                  <i class="fab fa-apple"></i>
                  <span>Download for <span>iOS</span></span>
                </a>
              </li>
              <li>
                <a class="item-2 download-btn-android" href="#">
                  <i class="fab fa-google-play"></i>
                  <span>Download for <span>Android</span></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-7">
          <img
            src="assets/images/untitled-project@2x4.png"
            class="download-img"
          />
        </div>
      </div>
    </div>

    <div class="download-shape-1">
      <img src="assets/images/shape/shape-15.png" alt="" />
    </div>
    <div class="download-shape-2">
      <img src="assets/images/shape/shape-14.png" alt="" />
    </div>
    <div class="download-shape-3">
      <img src="assets/images/shape/shape-13.png" alt="" />
    </div>
  </section>

  <!--====== APPIE DOWNLOAD PART ENDS ======-->

  <!--====== APPIE PRICING 2 PART START ======-->

  <section class="appie-pricing-2-area pb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">Simple pricing for Everyone</h3>
            <div class="appie-pricing-tab-btn">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                    >Monthly</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    ng
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                    >Yearly</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                  <div
                    class="pricing-one__single pricing-one__single_2 wow animated fadeInLeft"
                  >
                    <div class="pricig-heading">
                      <h6>Fresh</h6>
                      <div class="price-range">
                        <sup>$</sup> <span>04</span>
                        <p>/month</p>
                      </div>
                      <p>Get your 14 day free trial</p>
                    </div>
                    <div class="pricig-body">
                      <ul>
                        <li>
                          <i class="fal fa-check"></i> 60-day chat history
                        </li>
                        <li>
                          <i class="fal fa-check"></i> 15 GB cloud storage
                        </li>
                        <li><i class="fal fa-check"></i> 24/7 Support</li>
                      </ul>
                      <div class="pricing-btn mt-35">
                        <a class="main-btn" href="#">Choose plan</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div
                    class="pricing-one__single pricing-one__single_2 active wow animated fadeInUp"
                  >
                    <div class="pricig-heading">
                      <h6>Sweet</h6>
                      <div class="price-range">
                        <sup>$</sup> <span>16</span>
                        <p>/month</p>
                      </div>
                      <p>Billed $276 per website annually.</p>
                    </div>
                    <div class="pricig-body">
                      <ul>
                        <li>
                          <i class="fal fa-check"></i> 60-day chat history
                        </li>
                        <li>
                          <i class="fal fa-check"></i> 50 GB cloud storage
                        </li>
                        <li><i class="fal fa-check"></i> 24/7 Support</li>
                      </ul>
                      <div class="pricing-btn mt-35">
                        <a class="main-btn" href="#">Choose plan</a>
                      </div>
                      <div class="pricing-rebon">
                        <span>Most Popular</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div
                    class="pricing-one__single pricing-one__single_2 item-2 wow animated fadeInRight"
                  >
                    <div class="pricig-heading">
                      <h6>Juicy</h6>
                      <div class="price-range">
                        <sup>$</sup> <span>27</span>
                        <p>/month</p>
                      </div>
                      <p>Billed $276 per website annually.</p>
                    </div>
                    <div class="pricig-body">
                      <ul>
                        <li>
                          <i class="fal fa-check"></i> 60-day chat history
                        </li>
                        <li><i class="fal fa-check"></i> Data security</li>
                        <li>
                          <i class="fal fa-check"></i> 100 GB cloud storage
                        </li>
                        <li><i class="fal fa-check"></i> 24/7 Support</li>
                      </ul>
                      <div class="pricing-btn mt-35">
                        <a class="main-btn" href="#">Choose plan</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                  <div
                    class="pricing-one__single pricing-one__single_2 animated fadeInLeft"
                  >
                    <div class="pricig-heading">
                      <h6>Fresh</h6>
                      <div class="price-range">
                        <sup>$</sup> <span>32</span>
                        <p>/Yearly</p>
                      </div>
                      <p>Get your 14 day free trial</p>
                    </div>
                    <div class="pricig-body">
                      <ul>
                        <li>
                          <i class="fal fa-check"></i> 60-day chat history
                        </li>
                        <li>
                          <i class="fal fa-check"></i> 15 GB cloud storage
                        </li>
                        <li><i class="fal fa-check"></i> 24/7 Support</li>
                      </ul>
                      <div class="pricing-btn mt-35">
                        <a class="main-btn" href="#">Choose plan</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div
                    class="pricing-one__single pricing-one__single_2 active animated fadeInUp"
                  >
                    <div class="pricig-heading">
                      <h6>Sweet</h6>
                      <div class="price-range">
                        <sup>$</sup> <span>116</span>
                        <p>/Yearly</p>
                      </div>
                      <p>Billed $276 per website annually.</p>
                    </div>
                    <div class="pricig-body">
                      <ul>
                        <li>
                          <i class="fal fa-check"></i> 60-day chat history
                        </li>
                        <li>
                          <i class="fal fa-check"></i> 50 GB cloud storage
                        </li>
                        <li><i class="fal fa-check"></i> 24/7 Support</li>
                      </ul>
                      <div class="pricing-btn mt-35">
                        <a class="main-btn" href="#">Choose plan</a>
                      </div>
                      <div class="pricing-rebon">
                        <span>Most Popular</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div
                    class="pricing-one__single pricing-one__single_2 item-2 animated fadeInRight"
                  >
                    <div class="pricig-heading">
                      <h6>Juicy</h6>
                      <div class="price-range">
                        <sup>$</sup> <span>227</span>
                        <p>/Yearly</p>
                      </div>
                      <p>Billed $276 per website annually.</p>
                    </div>
                    <div class="pricig-body">
                      <ul>
                        <li>
                          <i class="fal fa-check"></i> 60-day chat history
                        </li>
                        <li><i class="fal fa-check"></i> Data security</li>
                        <li>
                          <i class="fal fa-check"></i> 100 GB cloud storage
                        </li>
                        <li><i class="fal fa-check"></i> 24/7 Support</li>
                      </ul>
                      <div class="pricing-btn mt-35">
                        <a class="main-btn" href="#">Choose plan</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE PRICING 2 PART ENDS ======-->

  <!--====== APPIE FAQ PART START ======-->

  <section class="appie-faq-area pb-95 pt-90">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">Frequently asked questions</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div
            class="faq-accordion wow fadeInRight mt-30"
            data-wow-duration="1500ms"
          >
            <div
              class="accrodion-grp wow fadeIn"
              data-wow-duration="1500ms"
              data-grp-name="faq-accrodion"
            >
              <div id="accordion">
                <div class="card faq-card">
                  <div id="headingOne">
                    <h4>How to get started?</h4>
                    <button
                      class="btn btn-primary card-btn"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <i class="fal fa-chevron-down"></i>
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    class="collapse"
                    aria-labelledby="headingOne"
                  >
                    <div class="card-body faq-card-body">
                      Install our application from Play Store or Apple Store,
                      create your account by providing email address or phone
                      number. Then enter the verification code send to your
                      email address or your phone.
                    </div>
                  </div>
                </div>
              </div>

              <div id="accordion">
                <div class="card faq-card" id="faq-create-live-session">
                  <div id="headingOne">
                    <h4>How to create live session?</h4>
                    <button
                      id="faq-btn-2"
                      class="btn btn-primary card-btn"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <i class="fal fa-chevron-down"></i>
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    aria-labelledby="headingOne"
                  >
                    <div class="card-body faq-card-body">
                      Creating a live must include date, time and image or just
                      select now and do a live immediately. Before going live
                      the company can select products or add a new product, but
                      the company must select the stock of each product that is
                      going to be available for sale on live session.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="faq-accordion wow fadeInRight mt-30"
            data-wow-duration="1500ms"
          >
            <div
              class="accrodion-grp wow fadeIn"
              data-wow-duration="1500ms"
              data-grp-name="faq-accrodion"
            >
              <div id="accordion">
                <div class="card faq-card">
                  <div id="headingOne">
                    <h4>
                      Will I be notified when new live session is created?
                    </h4>
                    <button
                      id="faq-btn-3"
                      class="btn btn-primary card-btn"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <i class="fal fa-chevron-down"></i>
                    </button>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingOne"
                  >
                    <div class="card-body faq-card-body">
                      Yes the client can follow the company to be notified for
                      every update that the company does, such as new product,
                      new live session, scheduled sessions.
                    </div>
                  </div>
                </div>
              </div>

              <div id="accordion">
                <div class="card faq-card">
                  <div id="headingOne">
                    <h4>Is the application free?</h4>
                    <button
                      id="faq-btn4"
                      class="btn btn-primary card-btn"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <i class="fal fa-chevron-down"></i>
                    </button>
                  </div>
                  <div
                    id="collapseFour"
                    class="collapse"
                    aria-labelledby="headingOne"
                  >
                    <div class="card-body faq-card-body">
                      We provide two types of subscription montly based and
                      commision based subscription.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="faq-text text-center pt-40">
            <p>
              Can't find an answer?
              <a href="mailto:demo@gmail.com" class="find-answer-faq-link"
                >Email us</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FAQ PART ENDS ======-->

  <!--====== APPIE PROJECT PART START ======-->

  <section class="appie-project-area pb-100 pt-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div
            class="appie-project-box wow animated slideInUp"
            data-wow-duration="1000ms"
            data-wow-delay="0ms"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="appie-project-content">
                  <h3 class="title">Go live know!</h3>
                  <p>We will email you only about this product.</p>
                  <form action="#">
                    <div class="input-box mt-30">
                      <input type="text" placeholder="Your email" />
                      <button>Subscribe</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE PROJECT PART ENDS ======-->

  <!--====== APPIE BACK TO TOP PART ENDS ======-->
  <div class="back-to-top" @click="scrollToTop()">
    <a><i class="fal fa-arrow-up"></i></a>
  </div>
  <!--====== APPIE BACK TO TOP PART ENDS ======-->

  <FooterPart />
</template>

<script>
import HeaderPart from "../header/header.vue";
import FooterPart from "../footer/footer.vue";
export default {
  name: "HomePage",
  components: {
    HeaderPart,
    FooterPart,
  },

  scrollToTop() {
    let el = document.getElementById("home-page-top");
    el?.scrollIntoView();
  },
};
</script>

<style>
.faq-card-body {
  padding: 0rem 1.5rem 1rem;
  margin: 0;
  color: #505056;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
}
#headingOne {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  align-items: flex-end;
}

#headingOne h4 {
  margin: 0;
  color: #0e1133;
  font-size: 18px;
  font-weight: 700;
}

.faq-card {
  border: none;
  margin-bottom: 30px;
}

.home-page-google-download-btn > a {
  background: transparent !important;
  border: 1px solid #0e1133;
  border-radius: 6px;
  color: #0e1133 !important;
  padding-top: 12px;
  padding-bottom: 12px;
}
.home-page-google-download-btn > a:hover {
  background: #0e1133 !important;
  border: 1px solid #0e1133;
  border-radius: 6px;
  color: #fff !important;
  padding-top: 12px;
  padding-bottom: 12px;
}

.home-page-store-download-btn > a {
  background: #0e1133 !important;
  border: 1px solid #0e1133;
  border-radius: 6px;
  color: #fff !important;
  padding-top: 12px;
  padding-bottom: 12px;
}
.home-page-store-download-btn > a:hover {
  background: transparent !important;
  border: 1px solid #0e1133;
  border-radius: 6px;
  color: #0e1133 !important;
  padding-top: 12px;
  padding-bottom: 12px;
}
.home-page-features-btn {
  background-color: #f84a6e;
  border-color: #f84a6e;
}
.home-page-features-btn:hover {
  background-color: transparent;
  border-color: #f84a6e;
  color: #f84a6e;
}
.homme-how-works-icon {
  display: flex;
  align-items: center;
}

.homme-how-works-icon > h4 {
  margin-left: 10px;
}
.homme-how-works-icon span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background: linear-gradient(
    90deg,
    rgba(130, 32, 196, 1) 0%,
    rgba(114, 81, 220, 1) 86%
  );
  border-radius: 50%;
  color: white;
  display: flex;
  margin-bottom: 10px;
}
.how-works-card:hover {
  border-top-color: #f84a6e !important;
}

.appie-download-content > span {
  color: #f84a6e;
}
.download-area-home {
  background: #f0f1f7 !important;
}
.download-btn-android {
  background: transparent !important;
}
.download-btn-android:hover {
  background-color: #0e1133 !important;
  color: #fff;
}
.download-btn-ios:hover {
  background: transparent !important;
  color: #0e1133;
}
.find-answer-faq-link {
  color: #f84a6e !important;
}

.btn[aria-expanded="true"] {
  background-color: #f84a6e !important;
  border-radius: 50%;
  border: none !important;
  box-shadow: none !important;
}
.btn[aria-expanded="true"] > i {
  color: white;
}
.btn[aria-expanded="false"] {
  border-radius: 50%;
  background: rgba(248, 74, 110, 0.1) !important;
  border: none;
  box-shadow: none !important;
}
.btn[aria-expanded="false"] > i {
  color: #f84a6e;
}

.back-to-top:hover {
  border: 1px solid #f84a6e;
  border-radius: 6px;
}
.back-to-top .fal {
  color: #fff;
}
.back-to-top > a {
  background-color: #f84a6e;
}
.back-to-top .fal:hover {
  color: #f84a6e;
}
.back-to-top > a:hover {
  background: transparent;
  border: #f84a6e;
}
.fal {
  color: #7151dc;
}
.home-8-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.home-8-shape-3 {
  position: absolute;
  top: 400px;
  right: 290px;
  z-index: -1;
}
.home-8-shape-4 {
  position: absolute;
  bottom: 195px;
  left: 195px;
  z-index: -1;
}
.home-intro-title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
  background: linear-gradient(90deg, #f84a6e 25%, #8220c4 75%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.home-intro-paragraph {
  color: #505056 !important;
  opacity: 1 !important;
}
#features {
  background-color: #fff !important;
}

@media only screen and (min-width: 732px) and (max-width: 1194px) {
  .how-works-card {
    height: 206px;
  }
}

@media (max-width: 731px) {
  .how-works-card {
    display: flex;
    height: 130px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 996px) {
  .download-img {
    display: none;
  }
}
@media only screen and (min-width: 985px) and (max-width: 1194px) {
  .faq-card {
    height: 75.2px !important;
  }
}
@media (max-width: 984px) {
  #faq-create-live-session {
    margin-bottom: 0;
    margin-top: 30px;
  }
}
@media (max-width: 502px) {
  .faq-card {
    height: 75.2px !important;
  }
}
</style>
