<template>
  <HeaderPart />

  <!--Page title part starts-->

  <section
    class="appie-hero-area appie-hero-5-area appie-hero-3-area"
    id="about-top"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="appie-hero-content text-center">
            <h1 class="appie-title about-intro-title">About Us</h1>
            <p class="about-intro-paragraph">
              We help build impactful software that drives digital
              transformation,
              <br />
              boost business productivity and facilitate its growth.
            </p>
            <div
              class="thumb mt-80 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <img src="assets/images/dashboard-resized.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-8-shape-2">
      <img src="assets/images/shape/4.png" alt="" />
    </div>
    <div class="home-8-shape-3">
      <img src="assets/images/shape/1.png" alt="" />
    </div>
    <div class="home-8-shape-4">
      <img src="assets/images/shape/2.png" alt="" />
    </div>
  </section>

  <!--Page title part ends-->

  <!--====== APPIE VALUES PART START ======-->

  <section class="appie-features-area pt-100 pb-130" id="features">
    <div class="container">
      <h2 class="title text-center mb-80 mt-100 key-values-title">
        Our key values
      </h2>
      <div class="row align-items-center">
        <div class="col-lg-4">
          <div class="appie-features-tabs-btn">
            <div
              class="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                class="nav-link active"
                id="v-pills-home-tab"
                data-toggle="pill"
                href="#v-pills-home"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
                ><i class="fas"><span>1</span></i
                >Total dedication and profesionalism</a
              >
              <a
                class="nav-link"
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                ><i class="fas"><span>2</span></i
                >Adaptability</a
              >
              <a
                class="nav-link"
                id="v-pills-messages-tab"
                data-toggle="pill"
                href="#v-pills-messages"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
                ><i class="fas"><span>3</span></i
                >Intellectual freedom</a
              >
              <a
                class="nav-link"
                id="v-pills-settings-tab"
                data-toggle="pill"
                href="#v-pills-settings"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                <i class="fas"><span>4</span></i
                >Full flexibility</a
              >
              <a
                class="nav-link"
                id="v-pills-diversity-tab"
                data-toggle="pill"
                href="#v-pills-diversity"
                role="tab"
                aria-controls="v-pills-diversity"
                aria-selected="false"
              >
                <i class="fas"><span>5</span></i
                >Diversity</a
              >
              <a
                class="nav-link"
                id="v-pills-recognition-tab"
                data-toggle="pill"
                href="#v-pills-recognition"
                role="tab"
                aria-controls="v-pills-recognition"
                aria-selected="false"
              >
                <i class="fas"><span>6</span></i
                >Recognition</a
              >
              <a
                class="nav-link"
                id="v-pills-openness-tab"
                data-toggle="pill"
                href="#v-pills-openness"
                role="tab"
                aria-controls="v-pills-openness"
                aria-selected="false"
              >
                <i class="fas"><span>7</span></i
                >Open-mindedness</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="tab-content" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6 key-values-img">
                  <div
                    class="appie-features-thumb text-center wow animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img
                      src="assets/images/key-values-about-us/proffesionalism.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3>Dedication and professionalism<br /></h3>
                    <p>
                      We organize a team of niche experts that show a high level
                      of technical competence. They are willing to dig into the
                      client’s business idea to come up with software products
                      that meet all the business needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img
                      src="assets/images/key-values-about-us/adaptability.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3>Adaptability <br /></h3>
                    <p>
                      We offer solutions that are easily adaptable to
                      fast-evolving business trends, changing conditions on the
                      market and other practices that may arise in the industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-messages"
              role="tabpanel"
              aria-labelledby="v-pills-messages-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img
                      src="assets/images/key-values-about-us/freedom.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3>Intellectual freedom <br /></h3>
                    <p>
                      We stick to the business model according to which everyone
                      in our team can express all possible ideas to bring real
                      values for each of the projects developed by Mangosoft.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-settings"
              role="tabpanel"
              aria-labelledby="v-pills-settings-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img
                      src="assets/images/key-values-about-us/flexibility.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3>Full flexibility <br /></h3>
                    <p>
                      We are fully flexible in the business' work process and
                      ready to provide development services tailored to
                      individual needs and requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-diversity"
              role="tabpanel"
              aria-labelledby="v-pills-diversity-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img
                      src="assets/images/key-values-about-us/diversity.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3>Diversity <br /></h3>
                    <p>
                      We stimulate the interest of our specialists to grow
                      professionally in diverse directions that allow us to
                      deliver fully-fledged software products with full
                      functionality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-recognition"
              role="tabpanel"
              aria-labelledby="v-pills-recognition-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img
                      src="assets/images/key-values-about-us/recognition.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3>Recognition <br /></h3>
                    <p>
                      We develop maximum effective tech solutions for our
                      clients so that they could be 100% satisfied with all the
                      results in business activities and return to us again and
                      again.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-recognition"
              role="tabpanel"
              aria-labelledby="v-pills-recognition-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img
                      src="assets/images/key-values-about-us/open-midedness.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-openness"
              role="tabpanel"
              aria-labelledby="v-pills-openness-tab"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div
                    class="appie-features-thumb text-center animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <img
                      src="assets/images/key-values-about-us/open-midedness.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="appie-features-content animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="600ms"
                  >
                    <h3>Open mindedness <br /></h3>
                    <p>
                      We are always open to all suggestions both from team
                      members and clients to provide totally transparent
                      processes in the workflow and effective cooperation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="features-shape-1">
      <img src="assets/images/shape/shape-6.png" alt="" />
    </div>
    <div class="features-shape-2">
      <img src="assets/images/shape/shape-7.png" alt="" />
    </div>
    <div class="features-shape-3">
      <img src="assets/images/shape/shape-8.png" alt="" />
    </div>
  </section>

  <!--====== APPIE VALUES PART ENDS ======-->

  <!--====== APPIE HOW IT WORK PART ENDS ======-->

  <div class="appie-how-it-work-area pt-100 pb-90">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="how-it-work-thumb text-center">
            <img src="assets/images/add product on live.png" alt="" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="appie-how-it-work-content">
            <h2 class="title">How It works</h2>
            <div class="row">
              <div class="col-sm-6">
                <div class="how-it-work-box">
                  <span>1</span>
                  <h5 class="title">
                    Download <br />
                    the app
                  </h5>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="how-it-work-box">
                  <span>2</span>
                  <h5 class="title">
                    Create <br />
                    your account
                  </h5>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="how-it-work-box">
                  <span>3</span>
                  <h5 class="title">
                    Create your <br />
                    first live stream
                  </h5>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="how-it-work-box">
                  <span>4</span>
                  <h5 class="title">
                    Enjoy your <br />
                    profits
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--====== APPIE HOW IT WORK PART ENDS ======-->

  <!--====== APPIE PROJECT PART START ======-->

  <section class="appie-project-area pb-100 pt-100" id="get-started">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div
            class="appie-project-box wow animated slideInUp"
            data-wow-duration="1000ms"
            data-wow-delay="0ms"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="appie-project-content">
                  <h3 class="title">Go live know!</h3>
                  <p>We will email you only about this product.</p>
                  <form action="#">
                    <div class="input-box mt-30">
                      <input type="text" placeholder="Your email" />
                      <button>Subscribe</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE PROJECT PART ENDS ======-->

  <!--====== APPIE BACK TO TOP PART ENDS ======-->
  <div class="back-to-top" @click="scrollToTop()">
    <a><i class="fal fa-arrow-up"></i></a>
  </div>
  <!--====== APPIE BACK TO TOP PART ENDS ======-->

  <FooterPart />
</template>
<script>
import HeaderPart from "../header/header.vue";
import FooterPart from "../footer/footer.vue";
export default {
  name: "AboutPage",
  components: {
    HeaderPart,
    FooterPart,
  },
  scrollToTop() {
    let el = document.getElementById("home-page-top");
    el?.scrollIntoView();
  },
};
</script>

<style>
.fal {
  color: #7151dc;
}
.home-breadcrumb-item1 {
  color: #fff !important;
}
.home-breadcrumb-item1:hover {
  color: #f84a6e !important;
}
.home-breadcrumb-item2 {
  color: #fff !important;
}
.home-breadcrumb-item2:hover {
  color: #f84a6e !important;
}
#service {
  background-color: #eff1f6;
}
.fas > span {
  font-size: 30px;
}
.key-values-title {
  font-size: 44px;
}
.how-it-work-box > span {
  color: #f84a6e !important;

  background: rgba(248, 74, 110, 0.1);
}
.how-it-work-box > span:hover {
  color: #fff !important;

  background: rgba(248, 74, 110);
}
.back-to-top:hover {
  border: 1px solid #f84a6e;
  border-radius: 6px;
}
.back-to-top .fal {
  color: #fff;
}
.back-to-top > a {
  background-color: #f84a6e;
}
.back-to-top .fal:hover {
  color: #f84a6e;
}
.back-to-top > a:hover {
  background: transparent;
  border: #f84a6e;
}
.about-intro-title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
  background: linear-gradient(90deg, #f84a6e 25%, #8220c4 75%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.about-intro-paragraph {
  color: #505056 !important;
  opacity: 1 !important;
}
.home-8-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.home-8-shape-3 {
  position: absolute;
  top: 400px;
  right: 290px;
  z-index: -1;
}
.home-8-shape-4 {
  position: absolute;
  bottom: 195px;
  left: 195px;
  z-index: -1;
}
#features {
  background-color: #fff !important;
}
@media (max-width: 1201px) {
  .about-intro-title {
    margin-top: 100px;
  }
}
</style>
