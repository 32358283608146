<template>
  <HeaderPart />
  <!--====== Company features below header section ======-->

  <section
    class="appie-hero-area appie-hero-5-area appie-hero-3-area"
    id="client-top"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="appie-hero-content text-center">
            <h1 class="appie-title client-intro-title">Client</h1>
            <p class="client-intro-paragraph">
              Shopping have never been easier. <br />
              Download our application for Android or iOS and start shopping.
            </p>
            <div
              class="thumb mt-80 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <img src="assets/images/feed.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-8-shape-2">
      <img src="assets/images/shape/4.png" alt="" />
    </div>
    <div class="home-8-shape-3">
      <img src="assets/images/shape/1.png" alt="" />
    </div>
    <div class="home-8-shape-4">
      <img src="assets/images/shape/2.png" alt="" />
    </div>
  </section>

  <!--====== Company introduction section ======-->

  <!--====== Company features part 2 ======-->

  <section
    class="appie-features-area-2 appie-features-area-5 pt-150 pb-100"
    id="features"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">
              Shop your favourite products with an ease
            </h3>
          </div>
        </div>
      </div>
      <div class="row mt-30 align-items-center">
        <div class="col-lg-6">
          <div class="appie-features-boxes">
            <div class="appie-features-box-item appie-features-box-5-item">
              <h4 class="title">Check your favorite company</h4>
              <p>
                Check which products are available for sale with possibility to
                sort and filter products.
              </p>
            </div>
            <div
              class="appie-features-box-item item-2 appie-features-box-5-item"
            >
              <h4 class="title">Receive notifications</h4>
              <p>
                Follow your favorite company and you will be notified for new
                products and scheduled live sessions.
              </p>
            </div>
            <div
              class="appie-features-box-item item-3 appie-features-box-5-item"
            >
              <h4 class="title">Join live session</h4>
              <p>
                Join live shopping session, check all available products and
                checkout without leaving the screen.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="appie-features-thumb wow animated fadeInRight"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <img src="assets/images/dashboard.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="features-shape-1">
      <img src="assets/images/shape/shape-15.png" alt="" />
    </div>
    <div class="features-shape-2">
      <img src="assets/images/shape/shape-14.png" alt="" />
    </div>
    <div class="features-shape-3">
      <img src="assets/images/shape/shape-13.png" alt="" />
    </div>
  </section>

  <!--====== Compnay features part 2 ======-->

  <!--====== Company fetures part 1 ======-->

  <section
    class="appie-service-area appie-service-5-area pt-190 pb-100"
    id="service"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">
              Designed with <br />
              your needs in mind
            </h3>
            <p>
              Look and search for different companies, checkout live sessions,
              checkout latest products and much more.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service appie-single-service-5 text-center mt-30 wow animated fadeInUp company-feature-card"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <div class="icon">
              <img
                src="assets/images/company-features-icons/authentication-icon.png"
                alt=""
              />
              <span>1</span>
            </div>
            <h4 class="appie-title">Authentication</h4>
            <p>
              Register with Facebook, Google, Apple ID or email address and
              password.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service appie-single-service-5 text-center mt-30 item-2 wow animated fadeInUp company-feature-card"
            data-wow-duration="2000ms"
            data-wow-delay="400ms"
          >
            <div class="icon">
              <img
                src="assets/images/company-features-icons/company-feed.png"
                alt=""
              />
              <span>2</span>
            </div>
            <h4 class="appie-title">Feed</h4>
            <p>
              Check latest updates, search for different companies, checkout
              live sessions, checkout latest products.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service appie-single-service-5 text-center mt-30 item-3 wow animated fadeInUp company-feature-card"
            data-wow-duration="2000ms"
            data-wow-delay="600ms"
          >
            <div class="icon">
              <img
                src="assets/images/company-features-icons/icons8-play-30.png"
                alt=""
              />
              <span>3</span>
            </div>
            <h4 class="appie-title">Lives</h4>
            <p>
              Join live shopping session, check products available for sale, add
              comments, purchase the product without leaving the screen.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service appie-single-service-5 text-center mt-30 item-4 wow animated fadeInUp company-feature-card"
            data-wow-duration="2000ms"
            data-wow-delay="800ms"
          >
            <div class="icon">
              <img
                src="assets/images/shopping-cart.png"
                alt=""
                class="my-orders-icon"
              />
              <span>4</span>
            </div>
            <h4 class="appie-title">Orders</h4>
            <p>
              Check the current status of your order or check previous
              purchases.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== Company features part 1 ======-->

  <!--====== APPIE PROJECT 3 PART START ======-->

  <section class="appie-project-area pt-100 pb-100" id="get-started">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div
            class="appie-project-box wow animated slideInUp"
            data-wow-duration="1000ms"
            data-wow-delay="0ms"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="appie-project-content">
                  <h3 class="title">Start shopping know.</h3>
                  <p>
                    Download our application from Play Store or Apple Store.
                  </p>
                  <ul>
                    <li>
                      <a href="#"
                        ><i class="fab fa-google-play"></i>
                        <span>Google Play</span></a
                      >
                    </li>
                    <li>
                      <a class="item-2" href="#"
                        ><i class="fab fa-apple"></i> <span>App Store</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE PROJECT 3 PART ENDS ======-->

  <!--====== APPIE BACK TO TOP PART ENDS ======-->
  <div class="back-to-top back-to-top-3" @click="scrollToTop()">
    <a><i class="fal fa-arrow-up"></i></a>
  </div>
  <!--====== APPIE BACK TO TOP PART ENDS ======-->
  <FooterPart />
</template>
<script>
import HeaderPart from "../header/header.vue";
import FooterPart from "../footer/footer.vue";
export default {
  name: "ClientPage",
  components: {
    HeaderPart,
    FooterPart,
  },
  scrollToTop() {
    let el = document.getElementById("client-top");
    el?.scrollIntoView();
  },
};
</script>
<style>
.company-feature-card {
  height: 300px;
}

.fal {
  color: #7151dc;
}
.back-to-top .fal {
  color: #fff;
}
.back-to-top > a:hover {
  background: transparent;
  border: #f84a6e;
}
.back-to-top .fal:hover {
  color: #f84a6e;
}
.back-to-top:hover {
  border: 1px solid #f84a6e;
  border-radius: 6px;
}
.my-orders-icon {
  width: 30px;
  height: 30px;
}

@media (max-width: 766px) {
  .menu-overlay-location {
    padding-left: 16px;
  }
}
.appie-project-content ul {
  margin: 41px 0 0;
  padding: 0;
  list-style-type: none;
}
.appie-project-content ul li {
  display: inline-block;
  margin-right: 8px;
}
@media (max-width: 767px) {
  .appie-project-content ul li {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-project-content ul li {
    margin-bottom: 0px;
  }
}
.appie-project-content ul li a {
  background: #fff;
  color: #0e1133;
  line-height: 40px;
  padding: 0 23px;
  border-radius: 6px;
  border: 1px solid #fff;
}
.appie-project-content ul li a i {
  padding-right: 6px;
}
.appie-project-content ul li a:hover {
  background: transparent;
  color: #fff;
}
.appie-project-content ul li a.item-2 {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.appie-project-content ul li a.item-2:hover {
  border: 1px solid #fff;
  background-color: #fff;
  color: #0e1133;
}
.home-8-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.home-8-shape-3 {
  position: absolute;
  top: 400px;
  right: 290px;
  z-index: -1;
}
.home-8-shape-4 {
  position: absolute;
  bottom: 195px;
  left: 195px;
  z-index: -1;
}
.client-intro-title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
  background: linear-gradient(90deg, #f84a6e 25%, #8220c4 75%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.client-intro-paragraph {
  color: #505056 !important;
  opacity: 1 !important;
}
.home-8-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.home-8-shape-3 {
  position: absolute;
  top: 400px;
  right: 290px;
  z-index: -1;
}
.home-8-shape-4 {
  position: absolute;
  bottom: 195px;
  left: 195px;
  z-index: -1;
}

@media (max-width: 1201px) {
  .client-intro-title {
    margin-top: 100px;
  }
}
</style>
