<template>
  <HeaderPart />
  <!--====== APPIE PAGE TITLE PART START ======-->

  <div class="appie-page-title-area" id="contact-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-page-title-item">
            <h3 class="title contact-intro-title">Contact</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/" class="breadcrumb-home"
                    >Home
                  </router-link>
                </li>
                <li
                  class="breadcrumb-item active breadcrumb-contact"
                  aria-current="page"
                >
                  Contact
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="home-8-shape-2">
      <img src="assets/images/shape/4.png" alt="" />
    </div>
    <div class="home-8-shape-3">
      <img src="assets/images/shape/1.png" alt="" />
    </div>
  </div>

  <!--====== APPIE PAGE TITLE PART ENDS ======-->

  <!-- Contact Start -->
  <section class="contact-section">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="contact--info-area">
            <h3>Get in touch</h3>
            <p>Looking for help? Fill the form and start a new adventure.</p>
            <div class="single-info">
              <h5>Headquaters</h5>
              <p>
                <i class="fal fa-home"></i>
                237 Kearny Street, CA, 94108, USA <br />
                Kozatska street 122/4 office 207, 03022, Ukraine
              </p>
            </div>
            <div class="single-info">
              <h5>Phone</h5>
              <p>
                <i class="fal fa-phone"></i>
                +1 424 413 5692<br />
                +380 (98) 067 14 98
              </p>
            </div>
            <div class="single-info">
              <h5>Support</h5>
              <p>
                <i class="fal fa-envelope"></i>
                contact@mangosoft.tech
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="contact-form">
            <h4>Let’s Connect</h4>
            <p>
              If you have any questions please fill in the form below. We will
              contact you as soon as possible
            </p>
            <form action="#" method="post" class="row">
              <div class="col-md-6">
                <input type="text" name="f-name" placeholder="First Name" />
              </div>
              <div class="col-md-6">
                <input type="text" name="l-name" placeholder="Last Name" />
              </div>
              <div class="col-md-6">
                <input type="email" name="email" placeholder="Email Address" />
              </div>
              <div class="col-md-6">
                <input type="number" name="phone" placeholder="Phone Number" />
              </div>
              <div class="col-md-12">
                <input type="text" name="suject" placeholder="Subject" />
              </div>
              <div class="col-md-12">
                <textarea
                  name="message"
                  placeholder="How can we help?"
                ></textarea>
              </div>
              <div class="col-md-6">
                <div class="condition-check">
                  <input id="terms-conditions" type="checkbox" />
                  <label for="terms-conditions">
                    I agree to the <a href="#">Terms & Conditions</a>
                  </label>
                </div>
              </div>
              <div class="col-md-6 text-right">
                <input
                  type="submit"
                  name="submit"
                  value="Send Message"
                  class="contact-form-submit-btn"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact End -->

  <!--====== APPIE BACK TO TOP PART ENDS ======-->
  <div class="back-to-top" @click="scrollToTop()">
    <a><i class="fal fa-arrow-up"></i></a>
  </div>
  <!--====== APPIE BACK TO TOP PART ENDS ======-->

  <FooterPart />
</template>
<script>
import HeaderPart from "../header/header.vue";
import FooterPart from "../footer/footer.vue";
export default {
  name: "ContactPage",
  components: {
    HeaderPart,
    FooterPart,
  },
  scrollToTop() {
    let el = document.getElementById("client-top");
    el?.scrollIntoView();
  },
};
</script>
<style>
.back-to-top:hover {
  border: 1px solid #f84a6e;
  border-radius: 6px;
}
.back-to-top .fal {
  color: #fff;
}
.back-to-top > a {
  background-color: #f84a6e;
}
.back-to-top .fal:hover {
  color: #f84a6e;
}
.back-to-top > a:hover {
  background: transparent;
  border: #f84a6e;
}
.appie-page-title-area {
  /* background-color: #eff1f6; */
  background-color: #eff1f6;
}
.contact-form-submit-btn {
  background-color: #f84a6e !important;
}
.home-8-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0 !important;
}
.home-8-shape-3 {
  position: absolute;
  top: 400px;
  right: 290px;
  z-index: 0 !important;
}
.home-8-shape-4 {
  position: absolute;
  bottom: 195px;
  left: 195px;
  top: 500px;
}
.contact-intro-title {
  font-size: 70px !important;
  line-height: 80px;
  font-weight: 700;
  background: linear-gradient(90deg, #f84a6e 25%, #8220c4 75%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.breadcrumb-home {
  color: #505056 !important;
  opacity: 1 !important;
}
.breadcrumb-contact {
  color: #505056 !important;
  opacity: 1 !important;
}
</style>
