<template>
  <HeaderPart />
  <!--====== Company features below header section ======-->

  <section
    class="appie-hero-area appie-hero-5-area appie-hero-3-area"
    id="company-top"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="appie-hero-content text-center">
            <h1 class="appie-title company-intro-title">Company</h1>
            <p class="company-intro-paragraph">
              Get started today and make profit with our live shopping
              application. <br />
              Create your first live stream and add products.
            </p>
            <div
              class="thumb mt-80 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <img src="assets/images/shop-page.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-8-shape-2">
      <img src="assets/images/shape/4.png" alt="" />
    </div>
    <div class="home-8-shape-3">
      <img src="assets/images/shape/1.png" alt="" />
    </div>
    <div class="home-8-shape-4">
      <img src="assets/images/shape/2.png" alt="" />
    </div>
  </section>

  <!--====== Company introduction section ======-->

  <!--====== Company features part 2 ======-->

  <section
    class="appie-features-area-2 appie-features-area-5 pt-150 pb-100"
    id="features"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">Manage your business with an ease</h3>
          </div>
        </div>
      </div>
      <div class="row mt-30 align-items-center">
        <div class="col-lg-6">
          <div class="appie-features-boxes">
            <div class="appie-features-box-item appie-features-box-5-item">
              <h4 class="title">Track your orders</h4>
              <p>
                Check the number of total orders and change order status as
                needed.
              </p>
            </div>
            <div
              class="appie-features-box-item item-2 appie-features-box-5-item"
            >
              <h4 class="title">Receive notifications</h4>
              <p>
                Decide what push notifications and emails you want to receive.
              </p>
            </div>
            <div
              class="appie-features-box-item item-3 appie-features-box-5-item"
            >
              <h4 class="title">Check your progress</h4>
              <p>
                Create reports of sales for specific perod and see how your
                business is performing.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="appie-features-thumb wow animated fadeInRight"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <img src="assets/images/dashboard.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="features-shape-1">
      <img src="assets/images/shape/shape-15.png" alt="" />
    </div>
    <div class="features-shape-2">
      <img src="assets/images/shape/shape-14.png" alt="" />
    </div>
    <div class="features-shape-3">
      <img src="assets/images/shape/shape-13.png" alt="" />
    </div>
  </section>

  <!--====== Compnay features part 2 ======-->

  <!--====== Company fetures part 1 ======-->

  <section
    class="appie-service-area appie-service-5-area pt-190 pb-100"
    id="service"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">
              Designed with <br />
              your needs in mind
            </h3>
            <p>
              Manage your products, create live shopping sessions and much more.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service appie-single-service-5 text-center mt-30 wow animated fadeInUp company-feature-card"
            data-wow-duration="2000ms"
            data-wow-delay="200ms"
          >
            <div class="icon">
              <img
                src="assets/images/company-features-icons/authentication-icon.png"
                alt=""
              />
              <span>1</span>
            </div>
            <h4 class="appie-title">Authentication</h4>
            <p>
              Client registration, login and verification process is based on
              E-Mail address and password.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service appie-single-service-5 text-center mt-30 item-2 wow animated fadeInUp company-feature-card"
            data-wow-duration="2000ms"
            data-wow-delay="400ms"
          >
            <div class="icon">
              <img
                src="assets/images/company-features-icons/company-feed.png"
                alt=""
              />
              <span>2</span>
            </div>
            <h4 class="appie-title">Feed</h4>
            <p>
              Track number of live streams, number of clients on each live
              stream followers and products.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service appie-single-service-5 text-center mt-30 item-3 wow animated fadeInUp company-feature-card"
            data-wow-duration="2000ms"
            data-wow-delay="600ms"
          >
            <div class="icon">
              <img
                src="assets/images/company-features-icons/icons8-play-30.png"
                alt=""
              />
              <span>3</span>
            </div>
            <h4 class="appie-title">Lives</h4>
            <p>
              Schedule live shooping event or go live immediatelly. Select
              products that will be available for sale.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div
            class="appie-single-service appie-single-service-5 text-center mt-30 item-4 wow animated fadeInUp company-feature-card"
            data-wow-duration="2000ms"
            data-wow-delay="800ms"
          >
            <div class="icon">
              <img
                src="assets/images/company-features-icons/shirt.png"
                alt=""
              />
              <span>4</span>
            </div>
            <h4 class="appie-title">Products</h4>
            <p>
              Manage your products. Check number of products, add new product or
              edit some product.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== Company features part 1 ======-->

  <!--====== APPIE PROJECT 3 PART START ======-->

  <section class="appie-project-area pt-100 pb-100" id="get-started">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div
            class="appie-project-box wow animated slideInUp"
            data-wow-duration="1000ms"
            data-wow-delay="0ms"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="appie-project-content">
                  <h3 class="title">Go live know.</h3>
                  <p>We will email you only about this product.</p>
                  <form action="#">
                    <div class="input-box mt-30">
                      <input type="text" placeholder="Your email" />
                      <button>Subscribe</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE PROJECT 3 PART ENDS ======-->

  <!--====== APPIE BACK TO TOP PART ENDS ======-->
  <div class="back-to-top back-to-top-3" @click="scrollToTop()">
    <a><i class="fal fa-arrow-up"></i></a>
  </div>
  <!--====== APPIE BACK TO TOP PART ENDS ======-->
  <FooterPart />
</template>
<script>
import HeaderPart from "../header/header.vue";
import FooterPart from "../footer/footer.vue";
export default {
  name: "CopmpanyPage",
  components: {
    HeaderPart,
    FooterPart,
  },
  scrollToTop() {
    let el = document.getElementById("client-top");
    el?.scrollIntoView();
  },
};
</script>
<style>
.company-feature-card {
  height: 300px;
}

.fal {
  color: #7151dc;
}
.back-to-top .fal {
  color: #fff;
}
.back-to-top > a:hover {
  background: transparent;
  border: #f84a6e;
}
.back-to-top .fal:hover {
  color: #f84a6e;
}
.back-to-top:hover {
  border: 1px solid #f84a6e;
  border-radius: 6px;
}
.home-8-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.home-8-shape-3 {
  position: absolute;
  top: 400px;
  right: 290px;
  z-index: -1;
}
.home-8-shape-4 {
  position: absolute;
  bottom: 195px;
  left: 195px;
  z-index: -1;
}

.company-intro-title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
  background: linear-gradient(90deg, #f84a6e 25%, #8220c4 75%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.company-intro-paragraph {
  color: #505056 !important;
  opacity: 1 !important;
}

@media (max-width: 766px) {
  .menu-overlay-location {
    padding-left: 16px;
  }
}
@media (max-width: 1146px) {
  .company-intro-title {
    margin-top: 100px;
  }
}
</style>
