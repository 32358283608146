<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area appie-footer-3-area appie-footer-5-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about-widget footer-about-widget-3 footer-about">
            <div class="logo">
              <a href="#"
                ><img
                  src="assets/images/app-logo/logo-pink.png"
                  alt=""
                  class="logo-footer"
              /></a>
            </div>
            <p>
              Download our application and get started. Checkout live sessions,
              search for companies, products and much more.
            </p>
            <a href="#">Read More <i class="fal fa-arrow-right"></i></a>
            <div class="social mt-30"></div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6">
          <div class="footer-navigation footer-navigation-3">
            <h4 class="title">Company</h4>
            <ul>
              <li>
                <router-link to="/about-us" class="header-link"
                  >About Us</router-link
                >
              </li>
              <li>
                <router-link to="/contact" class="header-link"
                  >Contact</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6">
          <div class="footer-navigation footer-navigation-3">
            <h4 class="title">Support</h4>
            <ul>
              <li>
                <router-link to="/company-features" class="header-link"
                  >Company</router-link
                >
              </li>
              <li>
                <router-link to="/client-features" class="header-link"
                  >Client</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">Get In Touch</h4>
            <ul>
              <li>
                <a href="#"
                  ><i class="fal fa-envelope"></i> contact@mangosoft.tech</a
                >
              </li>
              <li>
                <a href="#"><i class="fal fa-phone"></i> +1 424 413 5692</a>
                <a href="#"><i class="fal"></i> +380 (98) 067 14 98</a>
              </li>
              <li>
                <a href="#" class="menu-overlay-location"
                  ><i class="fal fa-map-marker-alt"></i> 237 Kearny Street, CA,
                  94108, USA</a
                >
                <a href="#"
                  ><i class="fal"></i> Kozatska street 122/4 office 207, 03022,
                  Ukraine</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
            class="footer-copyright d-flex align-items-center justify-content-between pt-35"
          >
            <div class="apps-download-btn">
              <ul>
                <li>
                  <a href="#"><i class="fab fa-apple"></i> Download for iOS</a>
                </li>
                <li>
                  <a class="item-2" href="#"
                    ><i class="fab fa-google-play"></i> Download for Android</a
                  >
                </li>
              </ul>
            </div>
            <div class="copyright-text">
              <p>Copyright © 2021 Appie. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {
  name: "FooterPart",
};
</script>

<style>
.logo-footer {
  width: 100px;
  height: 100px;
}
.footer-about {
  margin-top: 0;
}
</style>
